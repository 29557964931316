import {combineReducers} from "redux";

import {albumMedias} from "./creators/album.medias";

import {category} from "./creators/category";
import {categories} from "./creators/categories";


import {currentUser} from './creators/currentUser';

import {facettesTypesTemplates} from './creators/facettes.types.templates';
import {facettesType} from './creators/facettes.type';
import {facettesTypes} from './creators/facettes.types';

import {media} from "./creators/media";
import {medias} from "./creators/medias";

import {moderationContent} from './creators/moderationContent';
import {moderationContents} from './creators/moderationContents';


import {tagsMedia} from "./creators/tagsMedia";

import {stories} from './creators/stories';
import {story} from './creators/story';

import {reportings} from './creators/reportings';
import {reporting} from './creators/reporting';
import {reportingComments} from './creators/reporting.comments';
import {reportingComment} from './creators/reporting.comment';

import {tagsProtagonist} from './creators/tagsProtagonist';
import {tagsStories} from './creators/stories.tagsStories';
import {tagsListes} from './creators/tags.listes';
import {tagsList} from './creators/tags.list';

import {user} from './creators/user';
import {userArticles} from './creators/user.articles';
import {userCustomQuestions} from './creators/user.customQuestions';
import {userQuestion} from './creators/user.question';
import {userQuestions} from './creators/user.questions';
import {userAlbums} from './creators/user.albums';
import {userSessions} from './creators/user.sessions';
import {userSetPassword} from './creators/user.setPassword';
import {userWarning} from './creators/user.warning';
import {userWarnings} from './creators/user.warnings';

import {users} from './creators/users';
import {autologin} from './creators/user.autoLogin';

import login from './creators/login';
import {tagsListChildrens} from "./creators/tags.list.childrens";
import {tags} from "./creators/tags";
import {tag} from "./creators/tag";
import {transactionStats} from "./creators/transaction.stats";
import {userTransactions} from "./creators/user.transactions";

import {statsSiteDayly} from "./creators/stats.site.dayly";
import {statsSiteMonth} from "./creators/stats.site.month";
import {facettesTypesGenerateur} from "./creators/facettes.types.generateur";
import {facettes} from "./creators/facettes";
import {facette} from "./creators/facette";
import {tagsWarning} from "./creators/tagsWarning";
import {keywordsWarningsSetting} from "./creators/keywordsWarningSetting";
import {keywordsWarnings} from "./creators/keywordsWarnings";
import {keywordsWarning} from "./creators/keywordsWarning";
import {userRooms} from "./creators/user.rooms";
import {userRoom} from "./creators/user.room";
import {userRoomMessages} from "./creators/user.room.messages";
import {warnings} from "./creators/warnings";
import {userDeleteContents} from "./creators/user.removeContents";
import {configurations} from "./creators/configurations";
import {configuration} from "./creators/configuration";
import {status} from "./creators/status";
import {bannedMembers} from "./creators/bannedMembers";
import {domainNotWhiteList} from "./creators/antispam.domainNotWhiteList";
import {announcementMessages} from "./creators/announcementMessages";
import {products} from "./creators/products";
import {userItemSubscription} from "./creators/user.itemSubscription";
import {moderationCertifications} from "./creators/moderation.certifications";
import {moderationCertification} from "./creators/moderation.certification"
import { userCertifications } from "./creators/user.certifications";
import { userCertification } from "./creators/user.certification";

import {moderationContentFeatures} from "./creators/moderation.contentFeatures";
import {moderationContentRatings} from "./creators/moderation.contentRatings";
import {moderationContentModerations} from "./creators/moderation.contentModerations";

import {usersSearch} from "./creators/users.search";
import { moderationContentHistory } from "./creators/moderation.contentHistory";
import { moderationModeratorList } from "./creators/moderation.moderatorContentList";

/////////
export default combineReducers({
    albumMedias,
    autologin,
    category,
    categories,
    currentUser,
    facette,
    facettes,
    facettesTypesTemplates,
    facettesType,
    facettesTypes,
    facettesTypesGenerateur,
    login,
    keywordsWarning,
    keywordsWarnings,
    keywordsWarningsSetting,
    media,
    medias,
    moderationContent,
    moderationContents,
    announcementMessages,
    reportings,
    reporting,
    reportingComments,
    reportingComment,
    tag,
    tags,
    tagsMedia,
    tagsProtagonist,
    tagsStories,
    tagsListChildrens,
    tagsListes,
    tagsList,
    tagsWarning,
    stories,
    story,
    user,
    userArticles,
    userAlbums,
    userCustomQuestions,
    userQuestion,
    userQuestions,
    userRoom,
    userRoomMessages,
    userRooms,
    userSessions,
    userSetPassword,
    userDeleteContents,
    users,
    userWarning,
    userWarnings,
    transactions: userTransactions,
    transactionStats,
    statsSiteDayly,
    statsSiteMonth,
    warnings,
    configuration,
    configurations,
    status,
    bannedMembers,
    domainNotWhiteList,
    products,
    userItemSubscription,
    moderationCertifications,
    moderationCertification,
    userCertifications,
    userCertification,
    moderationContentFeatures,
    moderationContentRatings,
    moderationContentModerations,
    usersSearch,
    moderationContentHistory,
    moderationModeratorList
    // echo: echoReducer
});

