import React from "react";
import { connect } from "react-redux";
import "./ContentHistory.scss";
import { fetchModerationHistory, resetModerationHistory } from "../../creators/moderation.contentHistory";
import { CONTENT_RATINGS } from "../../const/content";


const ACTION_DESCRIPTIONS = {
    VALIDATE_CONTENT_RATING: "Validation du contentRating",
    VALIDATE_FEATURED_CONTENT: "Validation du contentFeatured",
    UPDATE_CONTENT_RATING: "Mise à jour du contentRating",
    UPDATE_FEATURED_CONTENT: "Mise à jour du contentFeatured",
};

class ContentHistory extends React.Component {
    constructor(props) {
        super(props);
        this.props.fetchModerationHistory(this.props.mediaId);
    }

    componentDidMount() {
        this.props.resetModerationHistory(); 
    }


    render() {
        const { moderationContentHistory, moderationContentHistoryLoading } = this.props;
        return (
            <div className="content-history">
                {moderationContentHistoryLoading && (
                    <div className="content-history__loading">Chargement en cours...</div>
                )}

                {!moderationContentHistoryLoading && moderationContentHistory && moderationContentHistory.length === 0 && (
                    <div className="content-history__empty">Aucun historique trouvé.</div>
                )}

                {!moderationContentHistoryLoading && moderationContentHistory && moderationContentHistory.length > 0 && (
                    <div className="content-history__list">
                        {moderationContentHistory.map((entry, index) => {
                            let newValue = entry.newValue || null;
                            let oldValue = entry.oldValue || null;

                            if(entry.action === "UPDATE_CONTENT_RATING"){

                                if(entry.oldValue === 0){
                                    oldValue = "Non défini";
                                }
                                else if(CONTENT_RATINGS[entry.oldValue - 1]){
                                    let selectContentRating = CONTENT_RATINGS[entry.oldValue - 1] || CONTENT_RATINGS[0];
                                    oldValue = selectContentRating && selectContentRating.label;
                                }
                                
                                if(entry.newValue === 0){
                                    newValue = "Non défini";
                                }
                                else if(CONTENT_RATINGS[entry.newValue - 1]){
                                    let selectContentRating = CONTENT_RATINGS[entry.newValue - 1] || CONTENT_RATINGS[0];
                                    newValue = selectContentRating && selectContentRating.label;
                                }
                                 
                            }

                            return (
                                <div key={index} className="content-history__item">
                                    {/* Ligne principale avec action, date et username */}
                                    <div className="content-history__item__header">
                                        <span className="content-history__username">
                                            {entry.owner.username}
                                        </span>
                                        <span className="content-history__action">
                                            {ACTION_DESCRIPTIONS[entry.action] || "Action inconnue"}
                                        </span>
                                        <span className="content-history__date">
                                            {new Date(entry.createdAt).toLocaleString()}
                                        </span>
                                    </div>
                                    {/* Affichage conditionnel des détails */}
                                    {entry.action === "UPDATE_CONTENT_RATING" && (
                                        <div className="content-history__details">
                                            <p>
                                                Ancienne valeur: <b>{oldValue}</b> → Nouvelle valeur:{" "}
                                                <b>{newValue}</b>
                                            </p>
                                        </div>
                                    )}
                                    {entry.action === "UPDATE_FEATURED_CONTENT" && (
                                        <div className="content-history__details">
                                            <p>
                                                Ancienne valeur: <b>{oldValue === true ? "Oui" : "Non"}</b> → Nouvelle valeur:{" "}
                                                <b>{newValue === true ? "Oui" : "Non"}</b>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    moderationContentHistory: state.moderationContentHistory.data, 
    moderationContentHistoryLoading: state.moderationContentHistory.loading, 
});

const mapDispatchToProps = {
    fetchModerationHistory,
    resetModerationHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentHistory);