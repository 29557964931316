import React from 'react';
import './ToggleSwitch.scss';

const ToggleSwitch = ({ options, value, onChange }) => {
  return (
    <div className="toggle-switch">
      {options.map((option) => (
        <button
          key={option.value}
          className={`toggle-button ${value === option.value ? 'active' : ''}`}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default ToggleSwitch;