import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Row, Table} from "react-bootstrap";
import ColoneTn from "../../components/ColoneTh";
import {LoadingBar} from "../../components/Loader";

import moment from "moment-timezone";
import {fetchUserRooms, resetUserRooms} from "../../../creators/user.rooms";
import Icon from "../../components/Icon";
import {Link} from "react-router-dom";



class MemberRooms extends React.Component {
    constructor(props) {
        super(props);
        this.currentUserId = this.props.match.params.id || false;
        this.filter = {
            sort : "-lastMessageCreatedAt",
            limit : 100
        }

        this.props.fetchUserRooms(this.currentUserId, this.filter);
    }
    componentWillUnmount() {
        this.props.resetUserRooms();
    }


    render() {
        return (
            <Fragment>
                <Row className={"mt-5 p-2"}>
                    <h4 className={"w-100"}>Rooms</h4>
                    {this.props.roomsLoading ? (<LoadingBar/>) : (
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <ColoneTn label={"Members"}  />
                                <ColoneTn label={"Last activity"}  />
                                <ColoneTn label={""}  />
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.rooms.map(room =>(
                                <tr key={room._id}>
                                    <td>
                                        <ul className={"rooms-list-users"}>
                                        {room.users.filter(user => user._id.toString() !== this.currentUserId.toString()).map(user =>(<li key={user._id}>{user.username}</li>))}
                                        </ul>
                                    </td>
                                    <td>{moment(room.lastMessageCreatedAt).format("DD-MM-YYYY HH:mm")}</td>
                                    <td width={"20px"}><Link to={`/member/${this.currentUserId}/rooms/${room._id}`} ><Icon type={"eye"} iconSize={20} /></Link></td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    )}
                </Row>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        rooms: state.userRooms.data,
        roomsInitialized: state.userRooms.initialized,
        roomsLoading: state.userRooms.loading
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserRooms,
    resetUserRooms
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MemberRooms);
