import React, { Component } from "react";
import "./ContentPhotos.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentRatingList from './contentRatingList';
import { faHistory, faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { legendToTitle } from "../../libs/helper";
import createLink from "../../libs/createLink";
import ContentHistory from "./ContentHistory";

class ContentPhotos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMedia: null,
            contentHistoryId : null
        };
    }

    setCurrentMedia = (media) => {
        this.setState({ currentMedia: media });
    };
        
    render() {
        const { 
            moderationContent = [], 
            mediaIdsSelected = [],
            handleMediaIdsSelected,
            contentRatingModerated = false,
            isAdmin = false
        } = this.props;
    
    
        return (
            <div className="content-rating-grid">
                {moderationContent.map(media => (
                        <div className={`content-card ${mediaIdsSelected.includes(media._id && media._id.toString()) ? "active" : ""}`} key={media._id && media._id.toString()}>
                            <div className="image-container">
                                <img
                                    src={`${media.previewUrl}?width=250`}
                                    alt={media.title}
                                    className="background-image"
                                    onClick={() =>handleMediaIdsSelected ? handleMediaIdsSelected(media._id) : null}
                                />
                                <button className="fullscreen-button"  onClick={() => this.setCurrentMedia(media)}><FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter}/></button>
                                {isAdmin && (<button className="info-button"  onClick={() => this.setState({contentHistoryId : media._id})}><FontAwesomeIcon icon={faHistory}/></button>)}

                            </div>
                            <ContentRatingList contentRating={media.contentRating} contentRatingModerated={contentRatingModerated} mediaId={media._id} />
                        </div>
                    )
                )}
                {this.state.currentMedia ? (
                    <Modal className={"modal-big-thumb modal-big"} centered backdrop={true} show={this.state.currentMedia ? true : false} onHide={()=>this.setState({currentMedia : null})}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Photo : <a href={createLink("mediaviewerPhoto", {media : this.state.currentMedia})}  rel="noopener noreferrer" target="_blank" title="Voir l'image">{legendToTitle(this.state.currentMedia.legend)}</a>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img alt={"img"} src={this.state.currentMedia.previewUrl} />
                        </Modal.Body>
                    </Modal>
                ) : null}  
                {this.state.contentHistoryId ? (
                    <Modal className={"modal-big-thumb modal-big"} centered backdrop={true} show={this.state.contentHistoryId ? true : false} onHide={()=>this.setState({contentHistoryId : null})}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                            Historique des modérations
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ContentHistory mediaId={this.state.contentHistoryId} />
                        </Modal.Body>
                    </Modal>
                ) : null}
            </div>
        )
    }
}


export default React.memo(ContentPhotos);
