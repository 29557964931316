
import { faShieldCheck, faShieldExclamation, faShieldSlash, faShield } from '@fortawesome/pro-solid-svg-icons';

const CONTENT_RATINGS = [
    {
        label : "SFW",
        value : 1,
        icon : faShieldCheck,
        className : "sw",
    },
    {
        label : "Erotique",
        value : 2,
        icon : faShield,
        className : "erotic",
    },
    {
        label : "+18",
        value : 3,
        icon : faShieldExclamation,
        className : "explicit",
    },
    {
        label : "Gros plan",
        value : 4,
        icon : faShieldSlash,
        className : "extrem",
    }
];

export {
    CONTENT_RATINGS
}