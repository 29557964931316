import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('USERS_SEARCH').plugin(axiosPlugin, {template: "array"});

let fetchUsersAction = redux.fetchActionCreate();


export const TYPES = redux.types;

export const fetchUsersSearch = (data) => fetchUsersAction(`/users/search-username`, data);

export const resetUsersSearch = () => redux.actions.reset();

export const usersSearch = redux.reducer();