import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('MODERATION_MODERATOR_LIST').plugin(axiosPlugin, {template: "array"});

let fetchAction = redux.fetchActionGet();


export const TYPES = redux.types;

export const fetchModerationModeratorList = (query = {}) => fetchAction(`/moderation-contents/moderator-content-list`);

export const resetModerationModeratorList = () => redux.actions.reset();


export const moderationModeratorList = redux.reducer();