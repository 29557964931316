import React from 'react';
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import { Can } from "../../ability-context";

const SubNavBar = props => {
    const { location, subNav = [] } = props;
    let pathname = location.pathname;
    if(pathname){
        let location2 = pathname.split("/");
        let last_element = location2[location2.length - 1]
        if(!isNaN(last_element)){
            pathname = pathname.replace(`/${last_element}`,"");
        }

    }
    return (
        <Nav variant="pills" activeKey={pathname} className='mb-4'>
            {subNav.map(item =>{
                if(item.permissions){
                    return (
                         <Can action={item.permissions} subject="all" key={item.label}>
                            <Nav.Item >
                                <Nav.Link as={Link} to={item.link} eventKey={item.link}>{item.label}</Nav.Link>
                            </Nav.Item>
                        </Can>
                    )
                }
                else{
                    return (
                        <Nav.Item key={item.label}>
                            <Nav.Link as={Link} to={item.link} eventKey={item.link}>{item.label}</Nav.Link>
                        </Nav.Item>
                    )
                }
            })}
        </Nav>

    )
};

export default SubNavBar;