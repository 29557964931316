import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import "./ContentFeature.scss"
import { LoadingBar } from '../../../components/Loader';
import { fetchModerationContentFeatures, resetModerationContentFeatures, updateModerationContentFeatures } from '../../../../creators/moderation.contentFeatures';
import { CONTENT_RATINGS } from '../../../../const/content';
import { config } from '../../../../config';
import { removeKeyEmpty } from '../../../../libs/helper';
import ContentPhotos from '../../../components/ContentPhotos';
import ContentVideos from '../../../components/ContentVideos';
import ToggleSwitch from '../../../components/toggleSwitch/ToggleSwitch';
import AutocompleteUser from '../../../components/AutocompleteUser';
import _ from 'lodash';

const kindArray = [
    {
        value : "gallery",
        label : "Photos"
    },
    {
        value : "video",
        label : "Vidéos"
    }
]

const filter = {
    gender : "",
    contentRating : "",
    kind: "gallery",
    ownerId : ""
}

class ContentFeature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMedia : null,
            selectMediaIds : [],
            filter
        };
        this.fetchModerationContentFeatures(filter);
        this.handleSelection = this.handleSelection.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleFilterKind = this.handleFilterKind.bind(this);
        this._submit = this._submit.bind(this);
    }

    UNSAFE_componentWillMount(){
        this.props.resetModerationContentFeatures();
    }

    fetchModerationContentFeatures(query = {}){
        this.props.resetModerationContentFeatures();
        query = removeKeyEmpty(query);
        this.setState({selectMediaIds : []});
        this.props.fetchModerationContentFeatures(query);
    }

    handleFilter(key, value){
        let filter = this.state.filter;
        filter = {...filter, [key] : value};
        this.setState({filter});
    }

    handleFilterKind(value){
        let filter = this.state.filter;
        filter = {...filter, kind : value};
        this.setState({filter});
        this.fetchModerationContentFeatures(filter)
    }

    handleSelection(mediaId){
        mediaId = mediaId.toString();
        let selectMediaIds = this.state.selectMediaIds || [];
        if(selectMediaIds.includes(mediaId)){
            selectMediaIds = selectMediaIds.filter(item => item !== mediaId);
        }
        else{
            selectMediaIds.push(mediaId);
        }
        this.setState({selectMediaIds});
    }

    _submit(){
        const idsSelected = this.state.selectMediaIds || [];
        const idsNoSelected = this.props.moderationContentFeatures.filter(media => !idsSelected.includes(media._id.toString())).map(media => media._id.toString());
        
        if(this.props.moderationContentFeatures.length){
            this.props.updateModerationContentFeatures({idsSelected, idsNoSelected}).then(() =>{
                this.fetchModerationContentFeatures(this.state.filter);
            })
        }
    }


    render() {
        const moderationContentFeatures = Array.isArray(this.props.moderationContentFeatures) ? this.props.moderationContentFeatures :  [];
        const isAdmin = _.get(this.props, "currentUser.role") === "administrator";
       
        return (
            <div id="content-features">
                <div className="content-features-header">
                    <div className="content-features-header-filter">
                        <div className="content-features-header-filter-input-group">
                            <ToggleSwitch
                                options={kindArray}
                                value={this.state.filter.kind}
                                onChange={(value) => this.handleFilterKind(value)}
                            />
                        </div>
                        <div className="content-features-header-filter-separation"></div>
                        <div className="content-features-header-filter-input-group">
                            <select 
                                id="filter-gender" 
                                className="filter-select" 
                                value={this.state.filter.gender} 
                                onChange={(e) => this.handleFilter("gender", e.target.value)}
                            >
                                <option value="">Tous les genres</option>
                                {config.genderListProfile.map((item, index) => (
                                    <option key={index} value={index + 1}>{item}</option>
                                ))}
                            </select>
                        </div>

                        <div className="content-features-header-filter-input-group">
                            <select 
                                id="filter-content-rating" 
                                className="filter-select" 
                                value={this.state.filter.contentRating} 
                                onChange={(e) => this.handleFilter("contentRating", e.target.value)}
                            >
                                <option value="">Tous les contents</option>
                                {CONTENT_RATINGS.map((item, index) => (
                                    <option key={index} value={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="content-features-header-filter-input-group">
                            <AutocompleteUser onUserSelect={(user) =>this.handleFilter("ownerId", user && user._id)} />
                        </div>
                        <div className="filter-right">
                            <button className="filter-submit" onClick={() =>this.fetchModerationContentFeatures(this.state.filter)} >Valider</button>
                        </div>
                    </div>
                </div>
                {this.props.moderationContentFeaturesLoading ? (
                    <LoadingBar />
                ) : (
                    <>
                    <p>
                        <strong>Médias à traiter :</strong> {this.props.moderationContentFeaturesTotal || 0}
                    </p>
                    {this.state.filter.kind === "video" ? (
                        <ContentVideos
                        moderationContent={moderationContentFeatures} 
                        contentRatingModerated={isAdmin}
                        mediaIdsSelected={this.state.selectMediaIds}
                        handleMediaIdsSelected={(mediaId) =>this.handleSelection(mediaId.toString())}
                        isAdmin={isAdmin}
                    />
                    ) : (
                        <ContentPhotos
                        moderationContent={moderationContentFeatures} 
                        contentRatingModerated={isAdmin}
                        mediaIdsSelected={this.state.selectMediaIds}
                        handleMediaIdsSelected={(mediaId) =>this.handleSelection(mediaId.toString())}
                        isAdmin={isAdmin}
                    />
                    )}
                    </>
                )}
                <div className="content-features-footer">
                    <button className="content-submit" onClick={() => this._submit()} disabled={!moderationContentFeatures.length}>Valider</button>
                </div>     
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        moderationContentFeatures : state.moderationContentFeatures.data,
        moderationContentFeaturesLoading : state.moderationContentFeatures.loading,
        moderationContentFeaturesInitialized : state.moderationContentFeatures.initialized,
        moderationContentFeaturesTotal : state.moderationContentFeatures.total,
        currentUser : _.get(state,"currentUser.data"),
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchModerationContentFeatures,
    resetModerationContentFeatures,
    updateModerationContentFeatures
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContentFeature);
