import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('MODERATION_CONTENT_MODERATIONS').plugin(axiosPlugin, {template: "array"});

let fetchAction = redux.fetchActionGet();


export const TYPES = redux.types;

export const fetchModerationContentModerations = (query = {}) => fetchAction(`/moderation-contents/medias-content-modarated?${queryString.stringify(query)}`);

export const resetModerationContentModerations = () => redux.actions.reset();


export const moderationContentModerations = redux.reducer();