import React, {Fragment} from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import history from '../../../libs/history';

import SubNavBar from "../../components/SubNavBar";
import ContentsMedias from "./Medias";
import ContentsStatus from "./Status";
import ContentsModerationMessages from "./announcementMessages/AnnouncementMessages";
import ModerationMessagesFormMessage from "./announcementMessages/FormMessage";

class Contents extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = '/contents';
        this.subNavBar = [
            {link : `${this.pathPage}/status`, label : "Statuts"},
            {link : `${this.pathPage}/announcement-messages`, label : "Announcement Messages"},
        ]
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.location !== this.props.location){
            // this.props.resetModerationContents();
            return true;
        }
        return false;
    }

    componentWillUnmount(){
        // this.props.resetModerationContents();
    }

    render() {
        return (
            <Fragment>
                <SubNavBar {...this.props} subNav={this.subNavBar}/>
                <Router history={history}>
                    <Switch>
                        <Route exact path={`${this.pathPage}/announcement-messages/:page([0-9]+)?`} component={ContentsModerationMessages}/>
                        <Route exact path={`${this.pathPage}/announcement-messages/nouveau`} component={ModerationMessagesFormMessage}/>
                        <Route exact path={`${this.pathPage}/announcement-messages/:moderationMessageId`} component={ModerationMessagesFormMessage}/>
                        <Route exact path={`${this.pathPage}/status/:page([0-9]+)?`} component={ContentsStatus}/>
                        <Route exact path={`${this.pathPage}/:page([0-9]+)?`} component={ContentsMedias}/>
                    </Switch>
                </Router>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
