import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {PageLoader} from "../../components/Loader";

import {fetchWarnings, resetWarnings} from "../../../creators/warnings";
import ColoneTn from "../../components/ColoneTh";
import DateSince from "../../components/Date";
import _ from "lodash";
import { Table} from "react-bootstrap";
import DraftContentReader from "../../components/editor/DraftContentReader";
import createLink from "../../../libs/createLink";


class ModerationsWarnings extends React.Component {
    constructor(props) {
        super(props);
        this.props.fetchWarnings({limit : 100, sort : "-createdAt"})
    }


    componentWillUnmount() {
        this.props.resetWarnings();
    }



    render() {
        return (!this.props.warningsInitialized) ? (<PageLoader />) :(
            <Fragment>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <ColoneTn label={"Date"} />
                        <ColoneTn label={"Tag"} />
                        <ColoneTn label={"User"} />
                        <ColoneTn label={"Moderateur"} />
                        <ColoneTn label={"Raison"} />
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.warnings.map(warning =>(
                        <tr key={warning._id}>
                            <td><DateSince date={warning.createdAt} currentFormat={false}/></td>
                            <td>{warning.tag.title}</td>
                            <td>{_.get(warning,'owner._id') ? <a href={`/member/${warning.owner._id}/warning`} target={"_blank"}>{warning.owner.username} </a> : 'Effacer'}</td>
                            <td>{_.get(warning,'moderator._id') ? <a href={createLink("userProfile", warning.moderator)} target={"_blank"}>{warning.moderator.username} </a> : 'Effacer'}</td>
                            <td><DraftContentReader contentRaw={warning.contentRaw} /></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        warnings : state.warnings.data,
        warningsLoading : state.warnings.loading,
        warningsInitialized : state.warnings.initialized,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchWarnings,
    resetWarnings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModerationsWarnings);
