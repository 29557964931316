import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { fetchUsersSearch, resetUsersSearch } from "../../creators/users.search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./AutocompleteUser.scss";

class AutocompleteUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: "",
            selectedUser: null,
        };

        // Debounce pour limiter les appels API
        this.debouncedFetchUsers = _.debounce(this.handleFetchUsers, 300);
    }

    componentDidMount() {
        this.props.resetUsersSearch(); // Réinitialise les suggestions au montage
    }

    // Appelle Redux pour récupérer les utilisateurs
    handleFetchUsers = (query) => {
        if (query.trim()) {
            this.props.fetchUsersSearch({ username: query });
        } else {
            this.props.resetUsersSearch();
        }
    };

    // Gestion des changements dans l'input
    handleInputChange = (e) => {
        const value = e.target.value;
        this.setState({ inputValue: value, selectedUser: null });
        this.debouncedFetchUsers(value);
    };

    // Sélectionne un utilisateur
    handleSelectUser = (user) => {
        this.setState({
            inputValue: user.username,
            selectedUser: user,
        });
        this.props.resetUsersSearch();
        if (this.props.onUserSelect) this.props.onUserSelect(user); // Callback
    };

    // Réinitialise le champ
    handleReset = () => {
        this.setState({
            inputValue: "",
            selectedUser: null,
        });
        this.props.resetUsersSearch();
        if (this.props.onUserSelect) this.props.onUserSelect(null);
    };

    render() {
        const { inputValue, selectedUser } = this.state;
        const { users, loading } = this.props;

        return (
            <div className="autocomplete-user">
               
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Rechercher un utilisateur"
                        value={inputValue}
                        onChange={this.handleInputChange}
                        disabled={!!selectedUser} 
                        className="autocomplete-input"
                    />
                    {selectedUser && (
                        <button className="reset-button" onClick={this.handleReset}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    )}
                    {loading && (
                        <div className="loading-spinner">
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </div>
                    )}
                </div>

                {users.length > 0 && (
                    <ul className="suggestions-list">
                        {users.map((user) => (
                            <li
                                key={user._id}
                                onClick={() => this.handleSelectUser(user)}
                                className="suggestion-item"
                            >
                                {user.username}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    users: state.usersSearch.data, 
    loading: state.usersSearch.loading, 
});

const mapDispatchToProps = {
    fetchUsersSearch,
    resetUsersSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteUser);