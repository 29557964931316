import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { Can } from "../../../ability-context";
import SubNavBar from "../../components/SubNavBar";

import ContentRating from "./contentRating/ContentRating";
import ContentFeature from "./contentFeature/ContentFeature";
import ContentModeration from "./contentModeration/ContentModeration";

const pathPage = "/classifications";
const subNavBarConfig = [
    { link: `${pathPage}`, label: "Contenu à taguer", permissions: ["is-admin", "contentRatingAccess"] },
    { link: `${pathPage}/selection-content`, label: "Contenu à mettre en avant", permissions: ["is-admin", "contentFeatureAccess"] },
    { link: `${pathPage}/moderation-content`, label: "Contenu déjà modéré", permissions: ["is-admin", "contentFeatureAccess"] },
];

class Classifications extends React.Component {
    render() {
        return (
            <Fragment>
                <SubNavBar {...this.props} subNav={subNavBarConfig} />
                <Switch>
                    <Route
                        path={pathPage}
                        exact
                        render={(props) => (
                            <Can action={["is-admin", "contentRatingAccess"]} subject="all">
                                <ContentRating {...props} />
                            </Can>
                        )}
                    />
                    <Route
                        path={`${pathPage}/selection-content`}
                        render={(props) => (
                            <Can action={["is-admin", "contentFeatureAccess"]} subject="all">
                                <ContentFeature {...props} />
                            </Can>
                        )}
                    />
                    <Route
                        path={`${pathPage}/moderation-content`}
                        render={(props) => (
                            <Can action={["is-admin", "contentFeatureAccess"]} subject="all">
                                <ContentModeration {...props} />
                            </Can>
                        )}
                    />
                </Switch>
            </Fragment>
        );
    }
}

export default Classifications;