import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import "./ContentModeration.scss"
import { LoadingBar } from '../../../components/Loader';
import { CONTENT_RATINGS } from '../../../../const/content';
import { config } from '../../../../config';
import { removeKeyEmpty } from '../../../../libs/helper';
import ContentPhotos from '../../../components/ContentPhotos';
import ContentVideos from '../../../components/ContentVideos';
import ToggleSwitch from '../../../components/toggleSwitch/ToggleSwitch';
import AutocompleteUser from '../../../components/AutocompleteUser';
import { fetchModerationContentModerations, resetModerationContentModerations } from '../../../../creators/moderation.contentModerations';
import Aqp from '../../../../libs/mk-react-aqp';
import PaginationPage from '../../../components/Pagination';
import { updateModerationContentFeatures } from '../../../../creators/moderation.contentFeatures';
import _ from 'lodash';
import { fetchModerationModeratorList, resetModerationModeratorList } from '../../../../creators/moderation.moderatorContentList';

const kindArray = [
    {
        value : "gallery",
        label : "Photos"
    },
    {
        value : "video",
        label : "Vidéos"
    }
];

const typeArray = [
    {
        value : "contentRatingModerated",
        label : "Déjà tagué"
    },
    {
        value : "contentFeaturedModerated",
        label : "Déjà mis en avant"
    }
];

const contentFeaturedArray = [
    {
        value : true,
        label : "Acceptés ( mis en avant )"
    },
    {
        value : false,
        label : "Refusés ( non mis en avant )"
    }
]

const filterInit = {
    gender : "",
    contentRating : "",
    kind : "gallery",
    ownerId : "",
    modoratorId : "",
    type : "contentRatingModerated",
    limit : 30,
    page : 1,
    contentFeatured : ""
}

class ContentModeration extends React.Component {
    constructor(props) {
        super(props);

        this.aqp = new Aqp({}, {
            blacklist: ['limit', 'page'],
            currentLink: "/classifications/moderation-content",
            filterDefault: filterInit
        });

        this.state = {
            currentMedia : null,
            selectMediaIds : [],
            filter: {...filterInit, ...this.aqp.query, page: this.props.match.params.page || 1}
        };
        this.fetchModerationContentModerations(filterInit);
        this.props.fetchModerationModeratorList();
        this.handleFilter = this.handleFilter.bind(this);
        this.handleFilterKind = this.handleFilterKind.bind(this);
        this.handleFilterType = this.handleFilterType.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
    }

    UNSAFE_componentWillMount(){
        this.props.resetModerationContentModerations();
        this.props.resetModerationModeratorList();
    }

    fetchModerationContentModerations(query = {}){
        this.props.resetModerationContentModerations();
        query = removeKeyEmpty(query);
        this.setState({currentKind : query.kind});
        this.props.fetchModerationContentModerations(query).then((response) => {
            let mediaIds = [];
            if (this.state.filter.type === "contentFeaturedModerated") {
                // Mettez à jour les IDs dans selectMediaIds
                const data = (response.payload && response.payload.data) || []
                mediaIds = data.filter(item => item.contentFeatured).map(item => item._id.toString());
            }
            this.setState({ selectMediaIds: mediaIds });
        });
    }

    handleSelection(mediaId){
        mediaId = mediaId.toString();
        let selectMediaIds = this.state.selectMediaIds || [];
        if(selectMediaIds.includes(mediaId)){
            this.props.updateModerationContentFeatures({idsNoSelected : [mediaId]})
            selectMediaIds = selectMediaIds.filter(item => item !== mediaId);
        }
        else{
            this.props.updateModerationContentFeatures({idsSelected : [mediaId]})
            selectMediaIds.push(mediaId);
        }
        this.setState({selectMediaIds});
    }

    handleFilter(key, value){
        let filter = this.state.filter;
        filter = {...filter, [key] : value, page : 1};
        this.setState({filter});
        
    }
    handleFilterKind(value){
        let filter = this.state.filter;
        filter = {...filter, kind : value, page : 1, modoratorId : ""};
        this.setState({filter});
        this.fetchModerationContentModerations(filter);
    }

    handleFilterType(value){
        let filter = this.state.filter;
        filter = {...filter, type : value, page : 1, modoratorId : ""};
        this.setState({filter});
        this.fetchModerationContentModerations(filter);
    }

    handleChangePage(page) {
        let filter = {...this.state.filter, page};
        this.setState({filter});
        this.fetchModerationContentModerations(filter);
        window.scrollTo(0, 0);
    }


    render() {
        const moderationContentModerations = Array.isArray(this.props.moderationContentModerations) ? this.props.moderationContentModerations :  [];
        const totalPage = this.props.moderationContentModerationsTotal ? Math.ceil(this.props.moderationContentModerationsTotal / this.state.filter.limit) : 0;
        const isAdmin = _.get(this.props, "currentUser.role") === "administrator";
       
        return (
            <div id="content-moderation">
                <div className="content-moderation-header">
                    <div className="content-moderation-header-filter card-filter">
                        <div className="content-moderation-header-filter-input-group">
                            <ToggleSwitch
                                options={typeArray}
                                value={this.state.filter.type}
                                onChange={(value) => this.handleFilterType(value)}
                            />
                        </div>
                        <div className="content-moderation-header-filter-separation"></div>
                        <div className="content-moderation-header-filter-input-group">
                            <ToggleSwitch
                                options={kindArray}
                                value={this.state.filter.kind}
                                onChange={(value) => this.handleFilterKind(value)}
                            />
                        </div>

                        <div className="content-moderation-header-filter">
                            <div className="content-moderation-header-filter-input-group">
                                <select 
                                    id="filter-gender" 
                                    className="filter-select" 
                                    value={this.state.filter.gender} 
                                    onChange={(e) => this.handleFilter("gender", e.target.value)}
                                >
                                    <option value="">Tous les genres</option>
                                    {config.genderListProfile.map((item, index) => (
                                        <option key={index} value={index + 1}>{item}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="content-moderation-header-filter-input-group">
                                <select 
                                    id="filter-content-rating" 
                                    className="filter-select" 
                                    value={this.state.filter.contentRating} 
                                    onChange={(e) => this.handleFilter("contentRating", e.target.value)}
                                >
                                    <option value="">Tous les contents</option>
                                    {CONTENT_RATINGS.map((item, index) => (
                                        <option key={index} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                            {this.state.filter.type === "contentFeaturedModerated" ? (
                                <div className="content-moderation-header-filter-input-group">
                                    <select 
                                        id="filter-content-content-featured" 
                                        className="filter-select"
                                        value={this.state.filter.contentFeatured} 
                                        onChange={(e) => this.handleFilter("contentFeatured", e.target.value)}
                                    >
                                        <option value="">Tous les contenus modérés</option>
                                        {contentFeaturedArray.map((item, index) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </div>
                            ) : null}
                            {isAdmin ? (
                                <div className="content-moderation-header-filter-input-group">
                                    <select 
                                        id="filter-content-content-featured" 
                                        className="filter-select"
                                        value={this.state.filter.modoratorId} 
                                        onChange={(e) => this.handleFilter("modoratorId", e.target.value)}
                                    >
                                        <option value="">Tous les modos</option>
                                        {this.props.moderationModeratorList.map((item, index) => (
                                            <option key={index} value={item._id}>{item.username}</option>
                                        ))}
                                    </select>
                                </div>
                            ) : null}
                            <div className="content-moderation-header-filter-input-group">
                                <AutocompleteUser onUserSelect={(user) =>this.handleFilter("ownerId", user && user._id)} />
                            </div>
                            <div className="filter-right">
                                <button className="filter-submit" onClick={() =>{
                                    this.fetchModerationContentModerations(this.state.filter);
                                }} >Valider</button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.moderationContentModerationsLoading ? (
                    <LoadingBar />
                ) : (
                    <>
                    <p>
                        <strong>Médias traités :</strong> {this.props.moderationContentModerationsTotal || 0}
                    </p>
                    {this.state.filter.kind === "video" ? (
                        <ContentVideos 
                            moderationContent={moderationContentModerations} 
                            mediaIdsSelected={this.state.selectMediaIds}
                            handleMediaIdsSelected={(mediaId) =>this.state.filter.type === "contentFeaturedModerated" ? this.handleSelection(mediaId.toString()) : null}
                            contentRatingModerated={true}
                            isAdmin={isAdmin}
                        />
                    ) : (
                        <ContentPhotos 
                            moderationContent={moderationContentModerations} 
                            mediaIdsSelected={this.state.selectMediaIds}
                            handleMediaIdsSelected={(mediaId) =>this.state.filter.type === "contentFeaturedModerated" ? this.handleSelection(mediaId.toString()) : null}
                            contentRatingModerated={true}
                            isAdmin={isAdmin}
                        />
                    )}
                    </>
                
                )}
                
                <div className="content-moderation-footer">
                    <PaginationPage
                        currentPage={this.state.filter.page}
                        pagesCount={totalPage}
                        {...this.aqp.queryUrl(this.state.filter, {})}
                        handleChangePage={this.handleChangePage}
                    />
                </div>     
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        moderationContentModerations : state.moderationContentModerations.data,
        moderationContentModerationsLoading : state.moderationContentModerations.loading,
        moderationContentModerationsInitialized : state.moderationContentModerations.initialized,
        moderationContentModerationsTotal: state.moderationContentModerations.total,
        moderationModeratorList : state.moderationModeratorList.data,
        //moderationModeratorList
        currentUser : _.get(state,"currentUser.data"),
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchModerationContentModerations,
    resetModerationContentModerations,
    updateModerationContentFeatures,
    fetchModerationModeratorList,
    resetModerationModeratorList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContentModeration);
