import React, {Fragment} from 'react';
import {Button, Col, Form} from "react-bootstrap";
import {config} from '../../config';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import _ from "lodash";
import {updateUser} from "../../creators/user";

import {SpinLoader} from "../components/Loader";
import FormFields from "../components/FormFields";
import createLink from "../../libs/createLink";
import moment from "moment-timezone";


class FormProfile extends React.Component {
    constructor(props) {
        super(props);
        this.listGender = [];
        this.listOrientation = [];
        this.listRole = Object.keys(config.role).map(key => ({ "value" : key, label: config.role[key] }));
        this.state = {location : this.location, ...this.props.user};

        config.genderList.forEach((item,index) =>{
            this.listGender.push({label : item, value : parseInt(index)});
        });
        config.genderOrientation.forEach((item,index) =>{
            this.listOrientation.push({label : item, value : parseInt(index)});
        });

        this.handleInformations = this.handleInformations.bind(this);
        this.handleGender = this.handleGender.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePermissions = this.handlePermissions.bind(this);
    }


    componentDidUpdate(prevProps){
        if(_.get(this.props,"user.role") && _.get(this.props,"user.role") !== _.get(prevProps,"user.role")){
            this.setState({role : this.props.user.role})
        }
    }

    handleInformations(index,name, value) {
        let informations = [...this.state.profile.informations];
        informations[index][name] = value;
        this.setState({profile : {...this.state.profile, informations}});
    };

    handleGender(value){
        let informations = [...this.state.profile.informations];
        value = parseInt(value);

        let sex = 0;
        let sex2 = 0;
        if(![1,3,5,6].includes(value)){
            sex = 1;
        }

        if([3,4].includes(value)){
            sex2 = 1;
        }


        if([3,4,5].includes(value)){
            informations[0] = {
                ...informations[0],
                sex
            }
            if(informations.length === 2){
                informations[1] = {
                    ...informations[1],
                    sex : sex2,
                }
            }
            else{
                informations.push({sex : sex2});
            }

        }
        else{
            informations = [{
                ...informations[0],
                sex
            }]
        }
        console.log("gender", value);
        console.log("informations", informations);
        this.setState({profile : {...this.state.profile, gender: value, informations} })
    }


    handleDate(date){
        if(date !== null){
            date = moment.utc(date).endOf('day').add(1, "days").format();
        }
        return date;
    }

    handlePermissions(key, value){
        let permissions = this.state.permissions || {};
        permissions = {...permissions, [key] : value};
        this.setState({permissions});
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.userLoading) {
            let user = this.state;
            delete user.__v;
            if(user.role ==="banned" && user.role !== this.props.user.role){
                user = {
                    ...user,
                    banned : {
                        reason : "",
                        roleBefore : this.props.user.role,
                        date : moment()
                    }
                }
            }
            if(user.role !=="banned" && user.role !== this.props.user.role){
                user = {
                    ...user,
                    banned : {
                        reason : "",
                        roleBefore : user.role,
                        date : moment()
                    }
                }
            }
            this.props.updateUser(this.props.user._id, user);
        }
    }

    render() {
        let currentUrl  = _.get(this.props,"match.url");
        //console.log("state : ", this.state.customer.aboDateEnd);
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                {this.props.isRisk ? <h3 className={"text-danger"}>Profil a risque</h3> : null }
                <FormFields
                    group={{controlId : "groupe"}}
                    type={"select"}
                    label={{
                        label : "Groupe"
                    }}
                    controle={{
                        name : "role",
                        value : this.state.role,
                        options : this.listRole,
                        onChange : (event) =>{this.setState({role : event.target.value})}
                    }}
                />
                <FormFields
                    group={{controlId : "pseudo"}}
                    type={"input"}
                    label={{
                        label : "Pseudo"
                    }}
                    controle={{
                        type : "text",
                        name : "username",
                        value : this.state.username,
                        onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                    }}
                    text = {{
                        text : <Fragment><a href={createLink("userProfile", this.props.user)} target={"new"}>Visite le profil de {this.props.user.username}</a> - <a href={`${currentUrl}/auto-login`} target={"new"}>Se connecter avec le compte {this.props.user.username}</a></Fragment>
                    }}
                />
                {this.props.userError.statusCode === 409 ? (
                    <div className={"text-danger"}>
                        Username exist déjà
                    </div>
                ) : null}
                <FormFields
                    group={{controlId : "email"}}
                    type={"input"}
                    label={{
                        label : "Email"
                    }}
                    controle={{
                        type : "email",
                        name : "email",
                        value : this.state.email && this.state.email.address,
                        onChange : (event) =>{ this.setState({email : {...this.state.email, address: event.target.value} })}
                    }}
                />
                <FormFields
                    group={{controlId : "linkExternal"}}
                    type={"input"}
                    label={{
                        label : "Lien externe"
                    }}
                    controle={{
                        type : "url",
                        name : "linkExternal",
                        value : this.state.profile.linkExternal,
                        placeholder : "https://",
                        onChange : (event) =>{this.setState({profile : {...this.state.profile, linkExternal : event.target.value}})}
                    }}
                />
                <FormFields
                    group={{controlId : "history"}}
                    type={"select"}
                    label={{
                        label : "Membre historique"
                    }}
                    controle={{
                        name : "history",
                        value : _.get(this.state,"isHistory") ? 1 : 0,
                        options : [{value: 0, label: "Non"}, {value : 1, label : "Oui"}],
                        onChange : (event) =>{ this.setState({isHistory : event.target.value === "1" ? 1 : 0 })}
                    }}
                />
                <FormFields
                    group={{controlId : "qualification"}}
                    type={"select"}
                    label={{
                        label : "Qualification"
                    }}
                    controle={{
                        name : "qualification",
                        value : _.get(this.state,"tagModerationRights.qualification") ? 1 : 0,
                        options : [{value: 0, label: "Non"}, {value : 1, label : "Oui"}],
                        onChange : (event) =>{ this.setState({tagModerationRights : {...this.state.tagModerationRights, qualification: event.target.value === "1" ? 1 : 0} })}
                    }}
                />
                <FormFields
                    group={{controlId : "verification"}}
                    type={"select"}
                    label={{
                        label : "Verification"
                    }}
                    controle={{
                        name : "verification",
                        value : _.get(this.state,"tagModerationRights.verification") ? 1 : 0,
                        options : [{value: 0, label: "Non"}, {value : 1, label : "Oui"}],
                        onChange : (event) =>{ this.setState({tagModerationRights : {...this.state.tagModerationRights, verification: event.target.value === "1" ? 1 : 0} })}
                    }}
                />
                <FormFields
                    group={{controlId : "treatmentVideo"}}
                    type={"select"}
                    label={{
                        label : "Traitement vidéo"
                    }}
                    controle={{
                        name : "treatmentVideo",
                        value : _.get(this.state,"tagModerationRights.treatmentVideo") ? 1 : 0,
                        options : [{value: 0, label: "Non"}, {value : 1, label : "Oui"}],
                        onChange : (event) =>{ this.setState({tagModerationRights : {...this.state.tagModerationRights, treatmentVideo: event.target.value === "1" ? 1 : 0} })}
                    }}
                />
                
                <FormFields
                    group={{controlId : "contentRatingAccess"}}
                    type={"select"}
                    label={{
                        label : "Interface de taguage"
                    }}
                    controle={{
                        name : "contentRatingAccess",
                        value : _.get(this.state,"permissions.contentRatingAccess") ? 1 : 0,
                        options : [{value: 0, label: "Non"}, {value : 1, label : "Oui"}],
                        onChange : (event) =>{ this.handlePermissions("contentRatingAccess",event.target.value === "1" ? 1 : 0)}
                    }}
                />
                <FormFields
                    group={{controlId : "contentFeatureAccess"}}
                    type={"select"}
                    label={{
                        label : "Interface de mise en avant"
                    }}
                    controle={{
                        name : "contentFeatureAccess",
                        value : _.get(this.state,"permissions.contentFeatureAccess") ? 1 : 0,
                        options : [{value: 0, label: "Non"}, {value : 1, label : "Oui"}],
                        onChange : (event) =>{ this.handlePermissions("contentFeatureAccess",event.target.value === "1" ? 1 : 0)}
                    }}
                />
                <FormFields
                    group={{controlId : "gender"}}
                    type={"select"}
                    label={{
                        label : "Genre"
                    }}
                    controle={{
                        name : "gender",
                        value : this.state.profile && this.state.profile.gender,
                        options : this.listGender,
                        onChange : (event) =>{ this.handleGender(event.target.value)}
                    }}
                />
                <FormFields
                    group={{controlId : "orientation"}}
                    type={"select"}
                    label={{
                        label : "Orientation"
                    }}
                    controle={{
                        name : "orientation",
                        value : this.state.profile && this.state.profile.informations && this.state.profile.informations[0] && this.state.profile.informations[0].orientation,
                        options : this.listOrientation,
                        onChange : (event) =>{this.handleInformations(0, "orientation", event.target.value)}
                    }}
                />
                {this.state.profile && (this.state.profile.gender === 3 || this.state.profile.gender === 4 || this.state.profile.gender === 5) && (
                    <FormFields
                        group={{controlId : "orintation2"}}
                        type={"select"}
                        label={{
                            label : "Orientation 2"
                        }}
                        controle={{
                            name : "orientation2",
                            value : this.state.profile && this.state.profile.informations && this.state.profile.informations[1] && this.state.profile.informations[1].orientation,
                            options : this.listOrientation,
                            onChange : (event) =>{this.handleInformations(1, "orientation", event.target.value)}
                        }}
                    />
                )}
            
                <Form.Row>
                    <FormFields
                        group={{as : Col, controlId : "createdAt"}}
                        type={"date"}
                        label={{
                            label : "Date d'inscription"
                        }}
                        controle={{
                            name : "createdAt",
                            value : this.state.createdAt,
                            onChange : (date) =>{this.setState({createdAt : date})},
                            disabled : true,
                            className : "w-100"
                        }}

                    />
                    <FormFields
                        group={{as : Col, controlId : "lastActivity"}}
                        type={"date"}
                        label={{
                            label : "Date d'activite"
                        }}
                        controle={{
                            name : "lastActivity",
                            value : this.state.lastActivity,
                            onChange : (date) =>{this.setState({lastActivity : date})},
                            disabled : true,
                            className : "w-100"
                        }}
                    />
                </Form.Row>
                <Form.Row>
                    <FormFields
                        group={{as : Col, controlId : "birthday"}}
                        type={"date"}
                        label={{
                            label : "Date de naissance"
                        }}
                        controle={{
                            name : "birthday",
                            id : "birthday",
                            value : this.state.profile && this.state.profile.informations && this.state.profile.informations[0] && this.state.profile.informations[0].birthday,
                            onChange : (date) =>{this.handleInformations(0, "birthday", this.handleDate(date))},
                            className : "w-100"
                        }}

                    />
                    {this.state.profile && (this.state.profile.gender === 3 || this.state.profile.gender === 4 || this.state.profile.gender === 5) && (
                        <FormFields
                            group={{as : Col, controlId : "birthday2"}}
                            type={"date"}
                            label={{
                                label : "Date de naissance"
                            }}
                            controle={{
                                name : "birthday",
                                value : this.state.profile && this.state.profile.informations && this.state.profile.informations[1] && this.state.profile.informations[1].birthday,
                                onChange : (date) =>{this.handleInformations(1, "birthday", this.handleDate(date))},
                                className : "w-100"
                            }}
                        />
                    )}
                </Form.Row>

                <FormFields
                    group={{controlId : "description"}}
                    type={"input"}
                    label={{
                        label : "A propos"
                    }}
                    controle={{
                        type : "input",
                        as : "textarea",
                        name : "description",
                        value : this.state.profile && this.state.profile.description,
                        onChange : (event) =>{ this.setState({profile : {...this.state.profile, description : event.target.value}})}
                    }}
                />
                {this.props.userError.statusCode === 409 ? (
                    <div className={"text-danger"}>
                        <p>Mise a jour impossible</p>
                        <p>Username exist déjà</p>
                    </div>
                ) : null}
                <Button variant="primary" type="submit" className={"btn-loading"}>
                    Valider {this.props.userLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Form>
        )
    }
}

//params Group as={Col},
// params Label = label
// params texte = texte
// params type de controle : input, select , radio,
// params controle = type (email, texte, number ), value , name, options
// error,

const mapStateToProps = (state) => {
    
    return {
        user: state.user.data,
        userError : state.user.error,
        userLoading: state.user.loading
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormProfile);
