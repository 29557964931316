import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('MODERATION_CONTENT_HISTORY').plugin(axiosPlugin);

let fetchReportingCommentAction = redux.fetchActionGet();

export const fetchModerationHistory = (mediaId) => fetchReportingCommentAction(`/moderation-contents/medias-content-history/${mediaId}`);
export const resetModerationHistory = () => redux.actions.reset();

export const TYPES = redux.types;

export const moderationContentHistory = redux.reducer();