import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchKeywordsWarnings, reseKeywordsWarnings} from "../../../creators/keywordsWarnings";
import {PageLoader} from "../../components/Loader";
import {Button, OverlayTrigger, Table, Tooltip, Col, Row, Modal} from "react-bootstrap";
import ColoneTn from "../../components/ColoneTh";
import DateSince from "../../components/Date";
import createLink from "../../../libs/createLink";
import _ from "lodash";
import {
    cancelKeywordsWarning,
    confirmedKeywordsWarning,
} from "../../../creators/keywordsWarning";
import FormFields from "../../components/FormFields";
import {Link} from "react-router-dom";
import {faAddressCard, faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


window.toggleEllipsis = function(ellipsis) {
    ellipsis.classList.toggle('visible');
    ellipsis.innerHTML = ellipsis.classList.contains('visible') ? ellipsis.getAttribute('data-value') : '';
}

// Remplace les textes trop loins d'un avertissement par [...]
function shrinkWithEllipsis(s, maxTextLength) {
    let split = s.split(/(<span.*?<\/span>)/);
    for ( let i = 0; i < split.length; i+=2 ) {
        let text = split[i];
        //let warning = split[i + 1];

        if ( text.length > maxTextLength) {
            let match;
            let half = Math.floor(maxTextLength / 2);

            // Determine la coupure en cherchant un mot complet
            let limitLeft;
            if ( i === 0 ) {
                limitLeft = 0; // Cas particulier d'un texte long tout au début
            }
            else {
                limitLeft = half;
                match = /.\b/.exec(text.substring(limitLeft - 1));
                if (match) {
                    limitLeft += match.index;
                }
            }

            let theoricalEllipsed = text.substring(limitLeft, text.length - half + 1);
            let reverseEllipsed = theoricalEllipsed.split("").reverse().join("");

            let limitRight;
            if ( i === split.length-1 ) {
                limitRight = text.length; // Cas particulier d'un texte long tout à la fin
            }
            else {
                limitRight = text.length - half;
                match = /.\b/.exec(reverseEllipsed);
                if (match) {
                    limitRight -= match.index;
                }
            }

            let left = text.substring(0, limitLeft); // Partie affichée à gauche
            let right = text.substring(limitRight, text.length); // Partie affichée à droite
            let ellipsed = text.substring(limitLeft, limitRight); // Partie remplacée par [...]

            split[i] = left + '<span class="ellipsis" onclick="toggleEllipsis(this)" data-value="' + ellipsed.replace(/"/g, '&quot;') + '"></span>' + right;
        }
    }

    return split.join('');
}

const ReplaceContentMatch = ({
         warning = {}
    }) => {
    let {keywords = [], content = ""} = warning;
    let keywordsMatch = keywords.map(keyword => keyword.keywords).join("|");

    keywordsMatch = keywordsMatch.replace(/[.*\-+?^${}()|[\]\\]/g, '\\$&');

    let regexpString = new RegExp(`(${keywordsMatch})`,'gmi');
    content = content.replace(/[&<>'"]/g,
        tag => ({
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            "'": '&#39;',
            '"': '&quot;',
            '*': '&#42;',

        }[tag]));
    content = content.replace(regexpString, `<span class="keyword-warning">$1</span>`);

    content = shrinkWithEllipsis(content, 80);

    return (
        <div dangerouslySetInnerHTML={{__html: content}} className={"warning-text"} />
    );
}


class Warning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStatus : 0,
            currentAction : {
                _id : null,
                kind : null,
                toggleAlert : false,
                banAccount : false,
                reasonBan : "",
                removeCurrentContent : false,
                removeAllContentUser : false
            }

        }
        this.fetchKeywordsWarnings = this.fetchKeywordsWarnings.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.updateModalAction = this.updateModalAction.bind(this);
        this.props.fetchKeywordsWarnings({limit : false, status : 0});
    }

    componentWillUnmount() {
        this.props.reseKeywordsWarnings();
    }

    fetchKeywordsWarnings(status = 0){
        this.props.reseKeywordsWarnings();
        this.props.fetchKeywordsWarnings({limit : false, status});
        this.setState({status})
    }

    linkToContent(warning, params = {}, childs = null){
        let {kind = "", owner = {}, targetContent = {}} = warning;
        let link = "";
        let linkContent = {
            Album : (data) => createLink("album", {...data.owner, album : data}),
            Article : (data) => createLink("article", {...data.owner, article : data.content}),
            Media : (data) => {
                if(data.content.kind === "video"){
                    return createLink("mediaviewerVideo", {...data.owner, media : data.content})
                }
                else{
                    return createLink("mediaviewerPhoto", {...data.owner, media : data.content})
                }
            },
            Status : (data) => {
                let owner = _.get(data, "content.owner");
                return owner ? createLink("userProfile", {...owner}) : "";
            },
            User : (data) => createLink("userProfile", {...data.owner}),
            RoomMessages : (data) => createLink("userProfile", {...data.owner}),
            Topic : (data) =>createLink("forumTopic", {...data.owner, topic : data.content, category : _.get(data,"content.forum")}),
            TopicComment : (data) =>createLink("forumTopic", {...data.owner, topic : _.get(data,"content.topic"), category : _.get(data,"content.topic.forum")}),
            Comment : (data) =>{
                let kind = _.get(data,"content.parentKind");
                if(kind === "media"){
                    if(data.content.parent.kind === "video"){
                        return createLink("mediaviewerVideo", {...data.owner, media : data.content.parent})
                    }
                    else{
                        return createLink("mediaviewerPhoto", {...data.owner, media : data.content.parent})
                    }
                }
                else if(kind === "article"){
                    return createLink("article", {...data.owner, article : data.content})
                }
                else if(kind === "status"){
                    return createLink("userProfile", {...data.owner});
                }

                return null;
            }
        }

        if(_.get(targetContent,"_id") && linkContent[kind]){
            link = linkContent[kind]({owner, content : targetContent});
        }

        if(link !== ""){
            return <a href={link} {...params} >{childs}</a>
        }
        else{
            return <Button variant="info" size="sm" className={"content-remove"} disabled>Voir</Button>
        }
    }

    confirmedSpam(){
        let data = {...this.state.currentAction};
        this.props.confirmedKeywordsWarning(data._id, {banAccount : data.banAccount, reasonBan: data.banAccount ? data.reasonBan : "", removeCurrentContent : data.removeCurrentContent, removeAllContentUser : data.removeAllContentUser});
        this.closeModal();
    }
    cancelSpam(id){
        this.props.cancelKeywordsWarning(id)
    }

    updateModalAction(key, value){
        let currentAction = this.state.currentAction;
        this.setState({currentAction : {...currentAction, [key] : value}});
    }

    openModal(warning){
        this.setState({currentAction : {toggleAlert : true, _id : warning._id, kind : warning.kind, banAccount : false, reasonBan : "", removeCurrentContent : false, removeAllContentUser : false}})
    }

    closeModal(){
        this.setState({currentAction : {toggleAlert : false, _id : null, kind : null, banAccount : false, reasonBan : "", removeCurrentContent : false, removeAllContentUser : false}})
    }

    render() {
        return !this.props.keywordsWarningsInitialized ? (<PageLoader/>) : (
            <>
                <Modal centered backdrop={false} show={this.state.currentAction.toggleAlert} onHide={()=>this.closeModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className={"text-danger"}>Voulez-vous faire une autre action ?</p>
                        <div>
                            <FormFields
                                type={"checkbox"}
                                checkBox={{
                                    id : `checkbox-banAccount`,
                                    name : "banAccount",
                                    checked : this.state.currentAction.banAccount,
                                    label : "Bannir le membre",
                                    onChange : () =>{this.updateModalAction("banAccount",!this.state.currentAction.banAccount)}
                                }}
                            />
                            {this.state.currentAction.banAccount ? (
                                <FormFields
                                    group={{controlId : "reasonBan"}}
                                    type={"input"}
                                    label={{
                                        label : "Pour quelle raison voulez vous bannir ce membre ?"
                                    }}
                                    controle={{
                                        as : "textarea",
                                        type : "text",
                                        name : "reasonBan",
                                        value : this.state.reasonBan,
                                        required : false,
                                        onChange : (event) =>{this.updateModalAction("reasonBan",event.target.value)}
                                    }}
                                />
                            ) : null}
                            {this.state.currentAction.kind !== "User" ? (
                                <FormFields
                                    type={"checkbox"}
                                    checkBox={{
                                        id : `checkbox-removeCurrentContent`,
                                        name : "removeCurrentContent",
                                        checked : this.state.currentAction.removeCurrentContent,
                                        label : "Effacer ce contenu",
                                        onChange : () =>{this.updateModalAction("removeCurrentContent",!this.state.currentAction.removeCurrentContent)}
                                    }}
                                />
                            ) : null}
                            <FormFields
                                type={"checkbox"}
                                checkBox={{
                                    id : `checkbox-removeAllContentUser`,
                                    name : "removeAllContentUser",
                                    checked : this.state.currentAction.removeAllContentUser,
                                    label : "Effacer tout le contenu de l'utilisateur",
                                    onChange : () =>{this.updateModalAction("removeAllContentUser",!this.state.currentAction.removeAllContentUser)}
                                }}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.closeModal()}>
                            Fermer
                        </Button>
                        <Button variant="primary" onClick={()=>this.confirmedSpam()}>
                            Confirmer
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Col sm={2}>
                    <FormFields
                        group={{controlId : "status"}}
                        type={"select"}
                        controle={{
                            name : "status",
                            value : this.state.status,
                            options : [{value : 0, label : "Nouveaux"}, {value : 1, label : "Spams"}, {value : 2, label : "Ignorés"}],
                            onChange : (event) =>{this.fetchKeywordsWarnings(parseInt(event.target.value))}
                        }}
                    />
                </Col>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <ColoneTn label={"Date"}  />
                        <ColoneTn label={"Auteur"}  />
                        <ColoneTn label={"Type"}  />
                        <ColoneTn label={"Texte"}  />
                        <ColoneTn label={"Action"}  />
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.keywordsWarnings.map(warning =>(
                        <tr key={warning._id}>
                            <td><DateSince date={warning.createdAt} currentFormat={false}/></td>
                            <td>
                                {_.get(warning,"owner._id") ? (
                                    <Row>
                                        <Col sm={12}>
                                            <FontAwesomeIcon icon={faAddressCard} /> - <a href={createLink("userProfile", {...warning.owner})} target={"_blank"}>{warning.owner.username}</a>
                                        </Col>
                                        <Col sm={12}>
                                            <FontAwesomeIcon icon={faEdit} /> - <Link to={`/member/${warning.owner._id}`} target={"_blank"}>{warning.owner.username}</Link>
                                        </Col>
                                    </Row>
                                ) : warning.authorName}

                            </td>
                            <td>
                                {warning.kind}
                                {warning.kind === "Comment" ? (
                                    `- ${_.get(warning, "targetContent.parentKind")}`
                                ) : null}
                            </td>
                            <td><ReplaceContentMatch key={`content_${warning._id}`} warning={warning} /></td>
                            <td>
                                <div className='actions'>
                                    <div className='action'>{this.linkToContent(warning, {target : "_blank"}, <Button variant="info" size="sm">Voir</Button>)}</div>
                                    <div className='action'>
                                        <OverlayTrigger
                                            key={`m_${warning._id}`}
                                            placement={"right"}
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    <ul className={"p-0 m-0 pl-3 text-left"}>{warning.keywords.map((keyword, index) => (<li key={`keyword_${index}`}>{keyword.rule.substr(0, 90)}</li>))}</ul>
                                                </Tooltip>
                                            }
                                        >
                                            <Button variant="secondary" className={"nocursor-pointer"} size="sm">Explication</Button>
                                        </OverlayTrigger>
                                    </div>
                                    {this.state.status !== 1 ? <div className='action'><Button variant="danger" size="sm" onClick={()=>this.openModal(warning)}>Spam</Button></div> : null}
                                    {this.state.status !== 2 ? <div className='action'><Button variant="warning" size="sm" onClick={()=>this.cancelSpam(warning._id)}>Ignorer</Button></div> : null }
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        keywordsWarnings : state.keywordsWarnings.data,
        keywordsWarningsLoading : state.keywordsWarnings.loading,
        keywordsWarningsInitialized: state.keywordsWarnings.initialized,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchKeywordsWarnings,
    confirmedKeywordsWarning,
    cancelKeywordsWarning,
    reseKeywordsWarnings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Warning);
