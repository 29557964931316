import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('MODERATION_CONTENT_RATINGS').plugin(axiosPlugin, {template: "array"});

let fetchAction = redux.fetchActionGet();
let updateAction = redux.fetchActionUpdate();


export const TYPES = redux.types;

export const fetchModerationContentRatings = (query = {}) => fetchAction(`/moderation-contents/medias-content-rating-no-modarated?${queryString.stringify(query)}`);
export const updateModerationContentRatings = (data = {}) => updateAction(`/moderation-contents/medias-content-rating-no-modarated`, data);

export const resetModerationContentRatings = () => redux.actions.reset();


export const moderationContentRatings = redux.reducer();