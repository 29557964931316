import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('MEDIA').plugin(axiosPlugin);

let fetchMediaAction = redux.fetchActionGetOne();

let createMediaAction = redux.fetchActionCreate();

let updateMediaAction = redux.fetchActionUpdate();

let deleteMediaAction = redux.fetchActionDelete();

export const TYPES = redux.types;

export const fetchMedia = (mediaId, query = {}) => fetchMediaAction(`/medias/${mediaId}?${queryString.stringify(query)}`);
export const createMedia = (mediaId, data = {}) => createMediaAction(`/medias`, data);
export const updateMedia = (mediaId, data = {}) => updateMediaAction(`/medias/${mediaId}`, data);
export const moveMedia = (mediaId, data = {}) => updateMediaAction(`/medias/${mediaId}/move`, data);
export const deleteMedia = (mediaId = {}) => deleteMediaAction(`/medias/${mediaId}`);

export const updateMediaContentRating = (mediaId, data = {}) => updateMediaAction(`/moderation-contents/medias-content-rating-no-modarated/${mediaId}`, data);

export const resetMedia = () => redux.actions.reset();

export const media = redux.reducer();