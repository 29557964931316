import history from "../libs/history";

export const LOGIN_USER = 'user/LOGIN';
export const LOGIN_USER_SUCCESS = 'user/LOGIN_SUCCESS';
export const LOGIN_USER_FAIL = 'user/LOGIN_FAIL';
export const LOGOUT_USER = 'user/LOGOUT';

const errorSkeleton = {
    errors: {},
    message: null,
    name: null,
    statusCode: null,
    error: null
};
const INITIAL_STATE = {
    loading: false,
    error: errorSkeleton
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGIN_USER:
            return {...state, loading: true};
        case LOGIN_USER_SUCCESS:
            return {...state, loading: false, error: errorSkeleton, authenticated: true};
        case LOGIN_USER_FAIL:
            return {
                loading: false,
                error: action.error.response
            };
        case LOGOUT_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
};


export function loginUser(data) {
    return {
        type: LOGIN_USER,
        payload: {
            request: {
                method: 'post',
                data,
                url: `/users/login-admin`
            }
        }
    };
}

export const logoutUser = () => {
    history.push("/connexion");
    window.location.reload();
    return dispatch => {
        // deleteAuthenticationToken();
        localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
        dispatch({type: LOGOUT_USER});
    };
};


// export const deleteAuthenticationToken = ()=>localStorage.removeItem(config.TOKEN_NAME);