import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {fetchCurrentUser} from "../../creators/currentUser";
import {loginUser} from '../../creators/login';
import {Container, Row, Col, Form, Button, Card, Alert} from "react-bootstrap";
import logo from '../../images/logo.png';
import history from '../../libs/history';


class Signin extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmitSignin = this.handleSubmitSignin.bind(this);
        this.state = {
            login: '',
            password: ''
        }
    }

    redirectTo() {
        let currentDomain = window.location.hostname;
        let referrer = document.referrer;
        let redirection = '/';
        if (referrer.indexOf(currentDomain) >= 0 && referrer.indexOf('/connexion') <= 0) {
            redirection = referrer.replace(window.location.origin, "");
        }
        return redirection;
    }

    handleKeyPress(event) {
        if (event.key === "Enter") {
            this.handleSubmitSignin(event)
        }
    }

    handleSubmitSignin(e) {
        e.preventDefault();
        if (!this.props.login.loading) {
            this.props.loginUser(this.state).then(({error}) => {
                if (!error) {
                    this.props.fetchCurrentUser();
                    history.push(this.redirectTo());
                };
            });
        }
    }


    render() {
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Form onSubmit={(e) => this.handleSubmitSignin(e)}>
                        <Col xs={12} style={{ paddingBottom: '24px' }}>
                            <img src={logo} alt={"logo"} />
                        </Col>
                        <Card style={{ padding: '24px' }}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Login</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrer votre Login"
                                name="login" value={this.state.login}
                                autoComplete={"username"}
                                onChange={e => this.setState({login: e.target.value})}
                                onKeyPress={(event) => this.handleKeyPress(event)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Mot de passe</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Entrer votre Mot de passe"
                                name="password" autoComplete="current-password" value={this.state.password}
                                onChange={e => this.setState({password: e.target.value})}
                                onKeyPress={(event) => this.handleKeyPress(event)}/>
                        </Form.Group>
                        {this.props.login.error && this.props.login.error.statusCode === 401 &&
                        <Alert variant="danger">Email ou mot de passe invalide</Alert>}
                        {this.props.login.error && this.props.login.error.statusCode === 429 &&
                        <Alert variant="danger">Trop de demandes, veuillez réessayer dans 10 minutes</Alert>}
                        <Button variant="primary" type="submit">
                            Connexion
                        </Button>
                        </Card>
                    </Form>
                </Row>
            </Container>
        )
    }
}


const mapStateToProps = state => ({
    login: state.login
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCurrentUser,
    loginUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
