import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('MODERATION_CONTENT_FEATURES').plugin(axiosPlugin, {template: "array"});

let fetchAction = redux.fetchActionGet();
let updateAction = redux.fetchActionUpdate();

export const TYPES = redux.types;

export const fetchModerationContentFeatures = (query = {}) => fetchAction(`/moderation-contents/medias-feature-no-modarated?${queryString.stringify(query)}`);
export const updateModerationContentFeatures = (data = {}) => updateAction(`/moderation-contents/medias-feature-no-modarated`, data);

export const resetModerationContentFeatures = () => redux.actions.reset();

export const moderationContentFeatures = redux.reducer();