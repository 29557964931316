

const legendToTitle = (legend, maxLength = 100) => {
    let text = legend;
    // Supprimer les URLs
    text = text.replace(/https?:\/\/[^\s]+/g, '');
    // Supprimer les caractères non alphabétiques ou numériques au début
    //text = text.replace(/^[^\w]+/, '');

    const cutoffIndex = Math.min(text.indexOf('\n'), maxLength);
    const title = text.slice(0, cutoffIndex === -1 ? maxLength : cutoffIndex) || "";

    return title;
};

const removeKeyEmpty = (data) =>{
 return Object.keys(data).reduce((acc, key) => {
        if (data[key] !== undefined && data[key] !== null && data[key] !== "") {
            acc[key] = data[key];
        }
        return acc;
    }, {});
}



export {
    legendToTitle,
    removeKeyEmpty
}